/** @jsx jsx */
import {jsx} from 'theme-ui'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import * as firebase from 'firebase/app'
import 'firebase/auth'
import Layout from 'components/Layout'
import useUser from 'hooks/useUser'
import {useEffect} from 'react'
import {navigate, PageProps} from 'gatsby'
import {PageContext} from 'gatsbyTypes'
import useAutoSelectLanguage from 'hooks/useAutoSelectLanguage'

type Props = PageProps<{}, PageContext>

const uiConfig: firebaseui.auth.Config = {
  signInOptions: [
    {
      provider: firebase.auth?.EmailAuthProvider.PROVIDER_ID,
      signInMethod: firebase.auth?.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
    },
  ],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false,
  },
}

const AccountPage: React.FC<Props> = ({pageContext}) => {
  useAutoSelectLanguage(pageContext)
  const {loading, isAuthenticated} = useUser()
  useEffect(() => {
    if (!loading && isAuthenticated) {
      navigate('/account/')
    }
  }, [loading, isAuthenticated])
  return (
    <Layout>
      {typeof window !== 'undefined' && (
        <StyledFirebaseAuth
          uiConfig={uiConfig}
          firebaseAuth={firebase.auth()}
        />
      )}
    </Layout>
  )
}

export default AccountPage
